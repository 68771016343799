<script lang="ts" setup>
const auth = useAuth()
useHead({
  title: 'AutoDistribution',
})

const steps = [
  'Configure',
  'Preview',
  'Submit for Review',
]
</script>

<template>
  <div>
    <NuxtLayout name="dashboard">
      <template #header>
        <div class="flex space-x-4 items-center">
          <NuxtLink to="/auto-distribution" class="flex items-center">
            <UIcon name="i-heroicons-arrow-left" class="w-5 h-5" />
          </NuxtLink>
          <div>AutoDistribution configurator</div>
        </div>
        <div>
          <UiSteps :steps="steps" :index="$route.meta.step" />
        </div>
      </template>
      <div>
        <div>
          <slot />
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>
